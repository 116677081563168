/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/pickadate@3.6.4/lib/compressed/picker.min.js
 * - /npm/pickadate@3.6.4/lib/compressed/picker.date.min.js
 * - /npm/pickadate@3.6.4/lib/compressed/picker.time.min.js
 * - /npm/pickadate@3.6.4/lib/compressed/legacy.min.js
 * - /npm/pickadate@3.6.4/lib/compressed/translations/nl_NL.min.js
 * - /npm/bootstrap-select@1.14.0-beta2/dist/js/bootstrap-select.min.js
 * - /npm/bootstrap-select@1.14.0-beta2/js/i18n/defaults-nl_NL.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
